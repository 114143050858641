/**@jsx jsx*/
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { Col } from "boostly-ui2"
import { serverURL } from "../../variables"
import axios from "axios"

import {
  TitlePrimary,
  TitleSecondary,
  Caption,
  TitleTertiary,
} from "../components/titles"
import Card from "../components/card"
import StripeCheckout from "react-stripe-checkout"

const Highlight = styled.div`
  margin-top: 16px;
  display: block;
  color: tomato;
`
const Description = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-family: sans-serif;
  line-height: 22px;
`
const chargeURL = `${serverURL}/api/stripe/charge`
const subscribeURL = `${serverURL}/api/stripe/subscribe`
const ProductCard = ({
  id,
  accountName,
  name,
  price,
  desc,
  className,
  type = "charge",
  disabled = false,
  ...props
}) => {
  const handleToken = async (token, address) => {
    try {
      const status = await axios.post(
        type === "subscription" ? subscribeURL : chargeURL,
        {
          token,
          id,
        }
      )
    } catch (e) {
      console.log("failed checkout")
    }
  }
  return (
    <Card className={className}>
      <div
        css={css`
          position: absolute;
          top: -6px;
          right: 14px;
          height: auto;
          width: auto;
          background: hsl(40, 88%, 73%);
          border-radius: 4px;
          color: white;
        `}
      >
        <TitleSecondary
          css={css`
            padding: 10px 12px;
            color: hsl(39, 54%, 24%);
          `}
        >
          {`$${price ? price : ""} ${type === "subscription" ? `/ month` : ""}`}
        </TitleSecondary>
      </div>
      <div
        css={css`
          position: absolute;
          top: 10px;
          right: 12px;
          font-size: 42px;
          color: white;
        `}
      ></div>
      <TitleSecondary>{accountName}</TitleSecondary>
      <hr />
      <Col space="between">
        <TitleTertiary
          css={css`
            margin-top: 12px;
          `}
        >
          You are about to purchase
        </TitleTertiary>
        <Highlight>{name}</Highlight>
        <Description
          css={css`
            margin-top: 20px;
          `}
        >
          {desc}
        </Description>
        <StripeCheckout
          // stripeKey="pk_test_iOFy8DoO1eFTF3kbChcya37L00p8bgMYX8"
          stripeKey="pk_live_ZLkl4WV2NGYY1PFgW48R8zmz006vjdytd6"
          token={handleToken}
          ammount={price * 100}
          name={name}
          disabled={disabled}
          css={css`
            margin-top: 20px;
            /* pointer-events: ${disabled ? `none` : `all`}; */
          `}
        />
      </Col>
    </Card>
  )
}

export default ProductCard
