/**@jsx jsx*/
import React, { useState } from "react"
import styled from "@emotion/styled"
import { useSpring, a } from "react-spring"
import { css, jsx } from "@emotion/core"

const Input = styled.input`
  border: none;
  height: 32px;
  border-radius: 4px;
  font-size: 1.25em;
  padding-left: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  box-sizing: border-box;
  width: 100%;
  -webkit-appearance: none;
  box-shadow: 0 12px 15px hsla(0, 0%, 0%, 0.1), 0 5px 3px hsla(0, 0%, 0%, 0.05);
  -webkit-box-shadow: 0 12px 15px hsla(0, 0%, 0%, 0.1),
    0 5px 3px hsla(0, 0%, 0%, 0.05);
  :focus {
    outline: none;
  }
`
const Placeholder = styled(a.span)`
  position: absolute;
  font-size: 20px;
  top: 0px;
  left: 0px;
  pointer-events: none;
`
const FloatInput = ({ placeholder, className, ...props }) => {
  // const [isFocus, setFocus] = useState(false)
  const [isFloating, setFloating] = useState(false)
  const [shouldReturn, setReturn] = useState(true)
  const handleFocus = e => {
    shouldReturn && setFloating(true)
    props.onFocus && props.onFocus()
  }
  const handleBlur = e => {
    shouldReturn && setFloating(false)
    props.onBlur && props.onBlur()
  }
  const onChange = e => {
    setReturn(e.target.value ? false : true)
    props.onChange && props.onChange(e)
  }
  const springStyle = useSpring({
    transform: isFloating ? `translate(4px, -18px)` : `translate(8px, 8px)`,
    fontSize: isFloating ? `14px` : `20px`,
    color: isFloating ? `black` : `gray`,
  })
  return (
    <div
      css={css`
        position: relative;
      `}
      className={className}
    >
      <Placeholder style={springStyle}>{placeholder}</Placeholder>
      <Input
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
      />
    </div>
  )
}

export default FloatInput
