/**@jsx jsx*/
import React, { useState, useMemo } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import axios from "axios"
import { Col } from "boostly-ui2"
import { navigate } from "@reach/router"
import nprogress from "nprogress"
import { SegmentedControl } from "segmented-control"

import { ButtonPrimary } from "../components/buttons"
import Layout from "../components/layout"
import FloatInput from "../components/floatInput"
import { TitleSecondary, TitleTertiary } from "../components/titles"
import ProductCard from "../components/productCard"
import { smallMT, medMT, largeMT } from "../components/spacing"
import FloatTextArea from "../components/floatTextArea"
import { serverURL } from "../../variables"

const Content = styled.div`
  /* display: flex;
  flex-direction: column; */
  z-index: 2;
  grid-row: 2;
  grid-column: 2;
  height: auto;
`

const Input = styled(FloatInput)`
  display: block;
`
nprogress.configure({ minimum: 0.1, trickleSpeed: 200, parent: `#progParent` })

const createProductURL = `${serverURL}/api/users/products`
const OneTimeChargeForm = ({ user = {} }) => {
  const [name, setName] = useState("")
  const [price, setPrice] = useState(null)
  const [desc, setDesc] = useState("")

  const nameInput = useMemo(
    () => (
      <Input
        css={medMT}
        placeholder="Name"
        onChange={e => setName(e.target.value)}
      />
    ),
    []
  )
  const priceInput = useMemo(
    () => (
      <Input
        css={medMT}
        placeholder="Price"
        type="number"
        onChange={e => setPrice(e.target.value)}
      />
    ),
    []
  )
  const floatTextArea = useMemo(
    () => (
      <FloatTextArea
        css={css`
          ${medMT};
        `}
        onChange={e => setDesc(e.target.value)}
        placeholder="Description"
      />
    ),
    []
  )

  const handleSubmit = async e => {
    e.preventDefault()
    nprogress.start()
    axios
      .post(
        createProductURL,
        {
          user: user,
          name: name,
          price: price,
          description: desc,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(res => {
        nprogress.done()
        navigate("/dashboard")
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <>
      <ProductCard
        css={smallMT}
        accountName={user ? user.name : ""}
        name={name}
        price={price}
        desc={desc}
        disabled={false}
      />
      <form
        css={css`
          ${smallMT}
        `}
        onSubmit={handleSubmit}
      >
        <Col space="evenly">
          {nameInput}
          {priceInput}
          {floatTextArea}
        </Col>
        <ButtonPrimary css={medMT}>Create</ButtonPrimary>
      </form>
    </>
  )
}

const createSubscriptionURL = `${serverURL}/api/products/subscriptions`
const SubscriptionChargeForm = ({ user = {} }) => {
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [price, setPrice] = useState(null)
  const [desc, setDesc] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    nprogress.start()
    axios
      .post(
        createSubscriptionURL,
        {
          userId: user.id,
          planId: id,
          name: name,
          price: price,
          description: desc,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(res => {
        nprogress.done()
        navigate("/dashboard")
      })
      .catch(e => {
        console.log(e)
      })
  }

  const idInput = useMemo(
    () => (
      <Input
        css={medMT}
        placeholder="Plan ID"
        onChange={e => setId(e.target.value)}
      />
    ),
    []
  )
  const nameInput = useMemo(
    () => (
      <Input
        css={medMT}
        placeholder="Name"
        onChange={e => setName(e.target.value)}
      />
    ),
    []
  )
  const priceInput = useMemo(
    () => (
      <Input
        css={medMT}
        placeholder="Price"
        type="number"
        onChange={e => setPrice(e.target.value)}
      />
    ),
    []
  )
  const floatTextArea = useMemo(
    () => (
      <FloatTextArea
        css={css`
          ${medMT};
        `}
        onChange={e => setDesc(e.target.value)}
        placeholder="Description"
      />
    ),
    []
  )

  return (
    <>
      <ProductCard
        css={smallMT}
        accountName={user ? user.name : ""}
        name={name}
        price={price}
        desc={desc}
        disabled={false}
        type="subscription"
      />
      <form
        css={css`
          ${smallMT} /* height: auto; */
        `}
        onSubmit={handleSubmit}
      >
        <Col space="evenly">
          <TitleTertiary
            css={css`
              ${medMT};
              font-weight: bold;
              color: tomato;
            `}
          >
            Note: This feature is still experimental. Email z@ztai.dev to get
            help setting up :)
          </TitleTertiary>
          {idInput}
          {nameInput}
          {priceInput}
          {floatTextArea}
          {/* <TextEditor css={css`margin-top: 30px;`}/> */}
        </Col>
        <ButtonPrimary css={medMT}>Create</ButtonPrimary>
      </form>
    </>
  )
}

const CreateProduct = () => {
  const { user } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Layout id="progParent">
      <Content>
        <SegmentedControl
          style={{
            margin: `0px`,
            minWidth: `280px`,
            minheight: `60px`,
            display: `flex`,
            fontSize: `20px`,
            borderRadius: `8px`,
            transform: `scale(0.65)`,
          }}
          name="oneDisabled"
          options={[
            { label: "One-time", value: 0, default: true },
            { label: "Recurring", value: 1 },
          ]}
          setValue={i => setSelectedIndex(i)}
        />
        <TitleSecondary>This is what your buyers will see.</TitleSecondary>
        {selectedIndex === 0 ? (
          <OneTimeChargeForm user={user} />
        ) : (
          <SubscriptionChargeForm user={user} />
        )}
        <a
          css={css`
            ${medMT};
            display: block;
          `}
          href="/dashboard"
        >
          Back to Dashboard
        </a>
      </Content>
    </Layout>
  )
}

export default CreateProduct
